import { useState, useEffect} from "react";
import '../App.css';
import '../styles.css';
import { useChat } from "../contexts/ChatProvider";
import {WidgetContainer} from "../components/WidgetContainer";
import '../url-search-params-polyfill'; // Import the polyfill
import liff from '@line/liff';

function LineApp() {
  const {
    loadConversation,
    setLoadConversation,
    setGreeting,
    setClientKey,
    setDataPrefix,
    setHeader,
    setUserId,
    setCustomTheme
  } = useChat();

  const [license, setLicense] = useState("");
  const initializeLiffApp = async (liff_id) => {
    try {
      await liff.init({ liffId: liff_id });
      if (liff.isLoggedIn()) {
        const profile = await liff.getProfile();
        const user_id = profile.userId;
        setUserId(user_id);
        setGreeting("Hi, How can I help you?")
        if(!loadConversation){
          setLoadConversation(true);
        }
      } else {
        liff.login();
      }
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect( () => {
    const urlParams = new URLSearchParams( window.location.search);
    
    const clientKey = urlParams.get("clientKey");
    setClientKey(clientKey);

    const dataPrefix = urlParams.get("dataPrefix");
    setDataPrefix(dataPrefix);

    const theme = urlParams.get("theme");
    if(theme) setCustomTheme("#".concat(theme));

    const header = urlParams.get("chatHeader");
    setHeader(header);

    const license = urlParams.get("license");
    setLicense(license);

    const liff_id = urlParams.get("liffId");
    initializeLiffApp(liff_id);
  },[]);

  return (
      <WidgetContainer license={license}/>
  );
}

export default LineApp;